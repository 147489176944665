.full {
    position: relative;
}

.focus *:focus-visible {
    outline: dashed red 5px;
}

.reaction {
    position: absolute;
}

.reaction, .reaction iframe {
    width: 100%;
    height: 100%;
}

.source, .source iframe {
    width: 100%;
    height: 100%;
}

.source.edit.pause, .source.edit.play:hover {
    opacity: 50%;
}

.source.edit.pause:hover, .source.edit.play {
    opacity: 100%;
}

.drag {
    pointer-events: none;
    opacity: 50%;
}

.sourcebox {
    position: absolute;
    /*top: 0;*/
    /*right: 0;*/
    /*width: 512px;*/
    /*height: 320px;*/
}

.nw, .ne, .sw, .se {
    position: absolute;
    z-index: 10000;
    width: 12px;
    height: 12px;
}

.move {
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 1.5em;
    cursor: move;
    background-color: lightgray;
    border-bottom: solid black 1px;
    opacity: 50%;
    text-align: center;
}

.move:hover {
    opacity: 100%;
}

.dragging {
    pointer-events: none;
}

.nw, .ne {
    top: 0;
}

.sw, .se {
    bottom: 0;
}

.nw, .sw {
    left: 0;
}

.ne, .se {
    right: 0;
}

.nw {
    cursor: nw-resize;
}

.ne {
    cursor: ne-resize;
}

.sw {
    cursor: sw-resize;
}

.se {
    cursor: se-resize;
}

.hide {
    display: none;
}

.lag {
    display: inline-block;
    height: 1.5em;
    background-color: yellow;
    max-width: 50%;
    position: relative;
}
